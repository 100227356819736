<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_21788_3728)">
      <path
        d="M3.28125 6.01562C4.7914 6.01562 6.01562 4.7914 6.01562 3.28125C6.01562 1.7711 4.7914 0.546875 3.28125 0.546875C1.7711 0.546875 0.546875 1.7711 0.546875 3.28125C0.546875 4.7914 1.7711 6.01562 3.28125 6.01562Z"
        stroke="white"
        stroke-opacity="0.4"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <g clip-path="url(#clip1_21788_3728)">
      <path
        d="M3.28125 14.4531C4.7914 14.4531 6.01562 13.2289 6.01562 11.7187C6.01562 10.2086 4.7914 8.98438 3.28125 8.98438C1.7711 8.98438 0.546875 10.2086 0.546875 11.7187C0.546875 13.2289 1.7711 14.4531 3.28125 14.4531Z"
        stroke="white"
        stroke-opacity="0.4"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <g clip-path="url(#clip2_21788_3728)">
      <path
        d="M11.7187 6.01562C13.2289 6.01562 14.4531 4.7914 14.4531 3.28125C14.4531 1.7711 13.2289 0.546875 11.7187 0.546875C10.2086 0.546875 8.98438 1.7711 8.98438 3.28125C8.98438 4.7914 10.2086 6.01562 11.7187 6.01562Z"
        stroke="white"
        stroke-opacity="0.4"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <g clip-path="url(#clip3_21788_3728)">
      <path
        d="M11.7187 14.4531C13.2289 14.4531 14.4531 13.2289 14.4531 11.7187C14.4531 10.2086 13.2289 8.98438 11.7187 8.98438C10.2086 8.98438 8.98438 10.2086 8.98438 11.7187C8.98438 13.2289 10.2086 14.4531 11.7187 14.4531Z"
        stroke="white"
        stroke-opacity="0.4"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_21788_3728">
        <rect width="6.5625" height="6.5625" fill="white" />
      </clipPath>
      <clipPath id="clip1_21788_3728">
        <rect
          width="6.5625"
          height="6.5625"
          fill="white"
          transform="translate(0 8.4375)"
        />
      </clipPath>
      <clipPath id="clip2_21788_3728">
        <rect
          width="6.5625"
          height="6.5625"
          fill="white"
          transform="translate(8.4375)"
        />
      </clipPath>
      <clipPath id="clip3_21788_3728">
        <rect
          width="6.5625"
          height="6.5625"
          fill="white"
          transform="translate(8.4375 8.4375)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
